import React, { useState } from "react";
import Navbar from "../components/navigation/Navbar";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";

export default function EmailBlast() {
  const loggedInUser = localStorage.getItem("loggedInUser");
  const [emailContent, setEmailContent] = useState("");
  const [subject, setSubject] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const handleEditorChange = (content) => {
    setEmailContent(content);
  };

  const handleSendEmail = async () => {
    try {
      const response = await axios.post("/api/send-email", {
        subject,
        content: emailContent,
        recipients: ["max.shapovalov@uniontechnologiesinc.com", "mshapovalov7@gmail.com"],
      });
      setStatusMessage("Emails sent successfully!");
      console.log(response.data);
    } catch (error) {
      setStatusMessage("Failed to send emails.");
      console.error(error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <Navbar loggedInUser={loggedInUser} />
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-semibold mb-4">Email Blast</h1>
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="w-full p-2 border rounded mb-4"
        />
        <Editor
          apiKey="lvm52exd88hqd7pqdgravh8h8zjcrqarvy7ejh9nq4hxpf9z"
          value={emailContent}
          onEditorChange={handleEditorChange}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
          }}
        />
        <button
          onClick={handleSendEmail}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Send Email
        </button>
        {statusMessage && (
          <p className="mt-4 text-green-600 font-medium">{statusMessage}</p>
        )}
      </div>
    </div>
  );
}
