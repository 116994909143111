import React, { useState } from "react";
import Modal from "./Modal";
import axios from "axios";
import { loggedInUser } from "../../helpers/localStorage";
import { Editor } from "@tinymce/tinymce-react"; // Import TinyMCE editor

export default function BulkEmailModal({ closeModal, selectedEmails, clearSelectedLeads }) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(""); // This will be managed by TinyMCE
  const [isSending, setIsSending] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [useJoinUs, setUseJoinUs] = useState(true); // New: For sender selection

  const repName = loggedInUser();

  const sendEmails = async () => {
    setIsSending(true);
    try {
      const formData = new FormData();
      formData.append("subject", subject);
      formData.append("message", message); // Get message from TinyMCE editor
      formData.append("repName", repName);
      formData.append("useJoinUs", useJoinUs);
      if (attachment) formData.append("attachment", attachment);

      selectedEmails.forEach((email) => formData.append("to", email));

      await axios.post(
        "https://unionapi.netlify.app/.netlify/functions/api/send-email",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      alert("Emails sent successfully!");
      clearSelectedLeads();
    } catch (error) {
      console.error("Error sending emails:", error);
      alert("Failed to send emails.");
    } finally {
      setIsSending(false);
      closeModal();
    }
  };

  const handleEditorChange = (content, editor) => {
    setMessage(content); // Set the content from TinyMCE to the state
  };

  return (
    <Modal closeModal={closeModal}>
      <h2 className="text-xl font-bold">Send Bulk Email</h2>
      <p className="mb-4 text-gray-700">{`You are about to send emails to ${selectedEmails.length} recipients.`}</p>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Subject</label>
        <input
          type="text"
          className="block w-full bg-gray-200 border rounded py-2 px-3"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Enter email subject"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Message</label>
        <Editor
          apiKey="lvm52exd88hqd7pqdgravh8h8zjcrqarvy7ejh9nq4hxpf9z" // Your TinyMCE API key
          value={message}
          onEditorChange={handleEditorChange}
          init={{
            height: 300,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | removeformat | help",
          }}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Attachment (optional)</label>
        <input
          type="file"
          className="block w-full bg-gray-200 border rounded py-2 px-3"
          onChange={(e) => setAttachment(e.target.files[0])}
        />
      </div>

      <div className="mb-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="form-checkbox"
            checked={useJoinUs}
            onChange={() => setUseJoinUs(!useJoinUs)}
          />
          <span className="ml-2 text-gray-700">
            Send from joinus@tpeu.ca instead of my personal email
          </span>
        </label>
      </div>

      <div className="flex gap-x-2 justify-end">
        <button onClick={closeModal} className="bg-red-500 text-white py-2 px-4 rounded-lg">
          Cancel
        </button>
        <button
          onClick={sendEmails}
          className="bg-green-500 text-white py-2 px-4 rounded-lg"
          disabled={isSending}
        >
          {isSending ? "Sending..." : "Send Emails"}
        </button>
      </div>
    </Modal>
  );
}
