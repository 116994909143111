import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import Leads from "./pages/Leads";
import LeadPage from "./pages/LeadPage";
import PrintTasks from "./pages/PrintTasks";

import {
  createHistoryRecord,
  increaseTimesKicked,
} from "./helpers/userActionHistory";
// import { logoutUser } from './helpers/logOut';
import "./App.css";

// Import the users.json file
import usersData from "./data/users.json";
import Reports from "./pages/Reports";
import EmailBlast from "./pages/EmailBlast";

function PrivateRoute({ element }) {
  // Check if 'loggedInUser' exists in local storage
  const loggedInUser = localStorage.getItem("loggedInUser");

  // If not logged in, redirect to the login page
  if (!loggedInUser) {
    return <Navigate to="/login" />;
  }

  // If logged in, render the protected route
  return element;
}

function App() {
  const [salesReps, setSalesReps] = useState([]);
  const loggedInUser = localStorage.getItem("loggedInUser");
  const inactiveDuration = 1800000; //30 minutes
  let inactiveTimer;

  useEffect(() => {
    const RecordHistory = async () => {
      if (loggedInUser) {
        await createHistoryRecord(loggedInUser);
      }
    };
    RecordHistory();
    // Extract sales reps from usersData
    const reps = usersData.map((user) => user.firstname);

    // Set the sales reps in the state
    setSalesReps(reps);

    // Initialize the inactive timer
    startInactiveTimer();

    // Add event listeners for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      clearTimeout(inactiveTimer);
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  const handleActivity = () => {
    // Reset the inactive timer on user activity
    clearTimeout(inactiveTimer);
    startInactiveTimer();
  };

  const startInactiveTimer = () => {
    // Set a timer to call handleInactive after inactiveDuration
    inactiveTimer = setTimeout(handleInactive, inactiveDuration);
  };

  const handleInactive = async () => {
    const lastActivityTimestamp = localStorage.getItem("lastActivityTimestamp");
    const currentTime = new Date().getTime();
    const inactiveTime = currentTime - lastActivityTimestamp;

    if (inactiveTime >= inactiveDuration) {
      await increaseTimesKicked();
      // Remove items from localStorage

      console.log("User has been inactive for", inactiveTime, "milliseconds");
    } else {
      // If the user has become active again before the specified duration, reset the timer
      if (loggedInUser) {
        startInactiveTimer();
      }
      console.log("User became active again before the specified duration");
    }
  };

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/leads"
            element={<PrivateRoute element={<Leads salesReps={salesReps} />} />}
          />

<Route
            path="/emailblast"
            element={
              <PrivateRoute element={<EmailBlast salesReps={salesReps}/>} />
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute element={<Reports salesReps={salesReps} />} />
            }
          />
          <Route
            path="/print"
            element={<PrivateRoute element={<PrintTasks />} />}
          />
          <Route
            path="/lead/:id"
            element={
              <PrivateRoute element={<LeadPage salesReps={salesReps} />} />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute element={<Dashboard salesReps={salesReps} />} />
            }
          />
          {/* Default route */}
          <Route
            path="*"
            element={
              loggedInUser ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
